import React from 'react';

export default function Education() {
    return (
        <div id="education">
            <br/><br/>
            <h1>
                Education & Work Experience
            </h1>
            <br/>
            <ul>
                <li> <b> Master studies </b> at the Czech Technical University in Prague (CTU) </li>
                <li> <b> Exchange program </b> at the University of Vermont, USA </li>
                <li> <b> Bachelor's degree </b> in Software Engineering at CTU</li>
            </ul><br/>
            <ul id="ul-job">
                <li> <b> Stratox Cloud Native </b> (Frontend Development & Product Documentation) </li>
            </ul><br/>
            <ul id="ul-team">
                <li> Member of the <b> STILL team </b> at the Czech Technical University
                (more info <a target="_blank" rel="noopener noreferrer" href="http://still.felk.cvut.cz/">here</a>) </li>
            </ul>
            <br/><br/><br/>
        </div>
    );
}
