import React from "react";

export default function Navigation() {
    return (
        <header>
            <nav>
                <ul>
                    <li> Vít Šesták / Portfolio </li>
                    <li><a href={"#about"}> About Me </a></li>
                    <li><a href={"#skills-detail"}> Skills </a></li>
                    <li><a href={"#education"}> Education </a></li>
                    <li><a href={"#projects"}> Projects </a></li>
                    <li><a href={"#contact"}> Contact </a></li>
                </ul>
            </nav>
        </header>
    )
}