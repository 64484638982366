import React from "react";
import About from "./About";
import Education from "./Education";
import Projects from "./Projects";
import Contact from "./Contact";
import SkillsOverview from "./SkillsOverview";
import SkillsDetail from "./SkillsDetail";

export default function ContentBox() {
    return (
        <main>
            <About />
            <SkillsOverview />
            <SkillsDetail />
            <Education />
            <Projects />
            <Contact />
        </main>
    )
}