import React, {useState} from "react";

const SkillsDetail = () => {

    const [selectedSkill, setSelectedSkill] = useState("");

    return (
        <div id="skills-detail">
            <div className="mobile">
                <ul>
                    <h3> Languages </h3><br/>
                    <li> Java</li>
                    <li> JavaScript</li>
                    <li> TypeScript</li>
                    <li> C++</li>
                    <li> Python</li>
                    <li> HTML5, CSS3</li>
                    <li> SQL</li>
                </ul>
                <ul>
                    <h3> Frameworks </h3><br/>
                    <li> React</li>
                    <li> Spring Boot</li>
                    <li> JUnit</li>
                    <li> Docusaurus</li>
                </ul>
                <ul>
                    <h3> Design Tools </h3><br/>
                    <li> Figma </li>
                    <li> Enterprise Architect</li>
                </ul>
                <ul>
                    <h3> Other Tools </h3><br/>
                    <li> Docker</li>
                    <li> Kubernetes (basics)</li>
                    <li> GitLab</li>
                    <li> BitBucket</li>
                    <li> Terminal</li>
                </ul>
            </div>
            <h3 className={"mobile"}> We can speak to each other in </h3><br/>
            <ul className={"mobile lang"}>
                <li> Czech</li>
                <li> English</li>
                <li> German</li>
                <li> Spanish (beginner)</li>
            </ul>
            <p className={"mobile"}>
                Apart from the IT world, I am a passionate saxophone player &#127927; and pianist &#127929;
            </p>

            <div id="skill-wrapper">
                <div id="skill-picker">
                    <span onMouseOver={() => setSelectedSkill("lang")}>Languages</span>
                    <span onMouseOver={() => setSelectedSkill("frame")}>Frameworks</span>
                    <span onMouseOver={() => setSelectedSkill("design-tools")}>Design Tools</span>
                    <span onMouseOver={() => setSelectedSkill("other-tools")}>Other Tools</span>
                    <span onMouseOver={() => setSelectedSkill("lan")}>I speak..</span>

                </div>
                <div id="skill-card">
                    {selectedSkill === "lang" ? (
                        <div>
                            <ul>
                                <li> Java</li>
                                <li> JavaScript</li>
                                <li> TypeScript</li>
                                <li> C++</li>
                                <li> Python</li>
                                <li> HTML5, CSS3</li>
                                <li> SQL</li>
                            </ul>
                        </div>
                    ) : selectedSkill === "frame" ? (
                        <div>
                            <ul>
                                <li> React</li>
                                <li> Spring Boot</li>
                                <li> JUnit</li>
                                <li> Docusaurus</li>
                            </ul>
                        </div>
                    ) : selectedSkill === "other-tools" ? (
                        <div>
                            <ul>
                                <li> Docker</li>
                                <li> Kubernetes (basics)</li>
                                <li> Git</li>
                                <li> BitBucket</li>
                                <li> Terminal</li>
                            </ul>
                        </div>
                    ) : selectedSkill === "design-tools" ? (
                        <div>
                            <ul>
                                <li> Enterprise Architect</li>
                                <li> Figma </li>
                            </ul>
                        </div>
                    ) : selectedSkill === "lan" ? (
                        <div>
                            <ul>
                                <li> Czech</li>
                                <li> English</li>
                                <li> German</li>
                                <li> Spanish (beginner)</li>
                            </ul>
                        </div>
                    ) : (
                        <div>
                            <ul id="ul-select">
                                <li className="arrow"> &#8678; </li>
                                <li> Select</li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SkillsDetail;