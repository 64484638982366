import React from "react";

export default function Intro() {
    return (
        <>
            <section id="intro">
                <img src="portrait3.png" alt="profile_photo"/>
                <div>
                <h1> Vít Šesták </h1>
                <p>
                    SW engineer and developer
                </p>
                </div>
            </section>
        </>
    )
}