import React from 'react';

export default function Contact() {
    return (
        <div id="contact">
            <br/><br/>
            <h1>
                Feel free to get in touch with me
            </h1>
            <br/>
            <p>
                <img src="linkedin.png" alt="linkedin" /><b>LinkedIn:</b> <a target="_blank" rel="noopener noreferrer"
                      href={"https://www.linkedin.com/in/v%C3%ADt-%C5%A1est%C3%A1k-39b541188"}> View profile </a><br/>
                &#128386; <b>Email:</b> sestavit@gmail.com
            </p>
        </div>
    );
}

