const SkillsOverview = () => {
    return (
        <div id="skills-overview">
            <h1>
                Which of these skills are interesting for you?
            </h1>
            <div className="circle-wrapper">
                <div id="first-item">Java</div>
                <div id="second-item">JavaScript</div>
                <div id="third-item">Docker</div>
                <div id="fourth-item">HTML, CSS</div>
                <div id="fifth-item">Enterprise Arch.</div>
                <div id="sixth-item">Git</div>
            </div>
        </div>
    )
}

export default SkillsOverview;