import Navigation from "./Navigation";
import Intro from "./Intro";
import Footer from "./Footer";
import ContentBox from "./ContentBox";

function App() {
  return (
    <>
      <Navigation />
      <Intro />
      <ContentBox />
      <Footer />
    </>
  );
}

export default App;
