import React from 'react';

export default function Projects() {
    return (
        <div id={"projects"}>
            <br/><br/>
            <h1>
                Projects &#10003;
            </h1>
            <br/>
            <ul>
                <li> <b> TERESA </b> - University team project at CTU for people in post-covid phase
                    (more info <a target="_blank" rel="noopener noreferrer" href="https://aktualne.cvut.cz/en/reports/20210721-teresa-project-enables-rehabilitation-of-covid-19-patients-in-the-home-environment">here</a>) </li> <br/>
                <li> <b> Collapse Blast Game</b> - Try out my interpretation of this well known game written in Vanilla JavaScript
                    &#128073;<a target="_blank" rel="noopener noreferrer"
                                href="https://collapseblast.000webhostapp.com/">here</a>&#128072;</li>
            </ul>
            <br/><br/><br/>
        </div>
    );
}

