import React from 'react';

export default function About() {
    return (
        <div id={"about"}>
            <br/><br/>
            <h1>
                I'm excited about developing both the frontend and backend side of web apps, analytical thinking,
                designing systems, and having fun with people around.
            </h1>
            <br/><br/>
        </div>
    );
}

